export const MODULE_PAYMENT_ACTIVE = false;
export const MODULE_SEARCH_CLIENT_ACTIVE = false;
export const MODULE_DISBURSAL_ACTIVE = false;
export const MODULE_ANNOTATIONS_ACTIVE = false;
export const MODULE_DETAIL_LOAN_ACTIVE = false;

export const DISBURSALS_LMSV2_URL =
  process.env.REACT_APP_ENVIRONMENT === "qa" ||
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? `https://${process.env.REACT_APP_ENVIRONMENT}.vanalms.com/v2/disbursals`
    : "https://vanalms.com/v2/disbursals";
export const SEARCH_CLIENTS_LMSV2_URL =
  process.env.REACT_APP_ENVIRONMENT === "qa" ||
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? `https://${process.env.REACT_APP_ENVIRONMENT}.vanalms.com/v2/searchClients`
    : "https://vanalms.com/v2/searchClients";
export const USER_DETAILS_LMSV2_URL =
  process.env.REACT_APP_ENVIRONMENT === "qa" ||
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? `https://${process.env.REACT_APP_ENVIRONMENT}.vanalms.com/v2/user`
    : "https://vanalms.com/v2/user";

export const LOAN_DETAILS_LMSV2_URL =
  process.env.REACT_APP_ENVIRONMENT === "qa" ||
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? `https://${process.env.REACT_APP_ENVIRONMENT}.vanalms.com/v2/loan`
    : "https://vanalms.com/v2/loan";
