import React from "react";
import PropTypes from "prop-types";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import { Route, Switch, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "@material/react-layout-grid/dist/layout-grid.css";
import Card from "../../common/card/card";
import CardHeader from "../../common/heading";
import CardContent from "../../common/card/cardcontent";
import CircleIcon from "../../common/circle/circleicon";
import Details from "./details/details";
import RequestList from "../../request/list/list";
import RequestListAdapter from "../../request/list/listadapter";
import RequestStatus from "../../../domain/request/status";
import { compose } from "../../../util/compose";
import { withApp } from "../../app/app";
import { withDataLayer } from "../../datalayer/datalayer";
import "./request.css";

class Request extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    dataLayer: PropTypes.object,
    app: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      created: null,
      documentSent: null,
      offerAccepted: null,
      reviewing: null,
      createdLength: null,
      documentSentLength: null,
      offerAcceptedLength: null,
      reviewingLength: null,
    };
  }

  getRequests(status) {
    return this.props.dataLayer.requests
      .getRequests({
        type: "status",
        status: status,
        associations: "users",
      })
      .then((requests) => Object.values(requests))
      .catch((error) =>
        this.props.app.onError({
          text: this.props.t("Error fetching requests."),
          data: error.details,
        })
      );
  }
  onFilter(key, rows) {
    this.setState({
      [key]: rows,
    });
  }
  onReload() {
    this.setState({ loading: true });

    const getStatusRequests = (status) => this.getRequests(status);

    const filterByOfferType = (items, offerType) =>
      items.filter((item) => item.state.offer_type === offerType);

    Promise.all([
      getStatusRequests(RequestStatus.created),
      getStatusRequests(RequestStatus.document_sent),
      getStatusRequests(RequestStatus.reviewing),
      getStatusRequests(RequestStatus.offer_accepted),
    ])
      .then(([created, documentSent, reviewing, offerAccepted]) => {
        const shortTermInstallments = "short_term_installments";
        const filteredDocumentSent = filterByOfferType(
          documentSent,
          shortTermInstallments
        );
        const filteredOfferAccepted = filterByOfferType(
          offerAccepted,
          shortTermInstallments
        );

        this.setState({
          created,
          documentSent: documentSent,
          reviewing,
          offerAccepted: filteredDocumentSent.concat(filteredOfferAccepted),
        });
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const offerAcceptedAndDocumentSend = this.state.offerAccepted || [];
    const offerAcceptedandDocumentSentL =
      offerAcceptedAndDocumentSend && offerAcceptedAndDocumentSend.length > 0
        ? offerAcceptedAndDocumentSend.length
        : 0;
    const createdL =
      this.state.created && this.state.created.length > 0
        ? this.state.createdLength
        : 0;
    const reviewingL =
      this.state.reviewing && this.state.reviewing.length > 0
        ? this.state.reviewingLength
        : 0;
    const documentL =
      this.state.documentSent && this.state.documentSent.length > 0
        ? this.state.documentSent.length
        : 0;

    return (
      <Switch>
        <Route path={`${this.props.match.path}/details/:loanRequestId`}>
          <Details />
        </Route>
        <Route path={this.props.match.path}>
          <Grid>
            <Row>
              <Cell columns={12}>
                <Row>
                  <Cell columns={12}>
                    <Card className="requests status-document-sent">
                      <CardHeader
                        title={this.props.t("Document sent requests")}
                        titleNote={`(${documentL})`}
                        subtitle={this.props.t("Waiting for approval")}
                        graphic={<CircleIcon light={true} icon="how_to_reg" />}
                      />
                      <CardContent>
                        <RequestList
                          adapter={RequestListAdapter}
                          loading={this.state.loading}
                          requests={this.state.documentSent}
                          onReload={this.onReload.bind(this)}
                          onFilter={this.onFilter.bind(this)}
                          onFilterCollection={"documentSentLength"}
                          type="document_sent"
                        />
                      </CardContent>
                    </Card>
                  </Cell>
                  <Cell columns={12}>
                    <Card className="requests status-created">
                      <CardHeader
                        title={this.props.t("Created requests")}
                        titleNote={`(${createdL})`}
                        subtitle={this.props.t("Recently created loans")}
                        graphic={
                          <CircleIcon light={true} icon="new_releases" />
                        }
                      />
                      <CardContent>
                        <RequestList
                          adapter={RequestListAdapter}
                          loading={this.state.loading}
                          requests={this.state.created}
                          onReload={this.onReload.bind(this)}
                          onFilter={this.onFilter.bind(this)}
                          onFilterCollection={"createdLength"}
                        />
                      </CardContent>
                    </Card>
                  </Cell>
                  <Cell columns={12}>
                    <Card className="requests status-reviewing">
                      <CardHeader
                        title={this.props.t("Reviewing requests")}
                        titleNote={`(${reviewingL})`}
                        subtitle={this.props.t("Requests from flagged users.")}
                        graphic={<CircleIcon light={true} icon="flag" />}
                      />
                      <CardContent>
                        <RequestList
                          adapter={RequestListAdapter}
                          loading={this.state.loading}
                          requests={this.state.reviewing}
                          onReload={this.onReload.bind(this)}
                          onFilter={this.onFilter.bind(this)}
                          onFilterCollection={"reviewingLength"}
                        />
                      </CardContent>
                    </Card>
                  </Cell>
                  <Cell columns={12}>
                    <Card className="requests status-document-sent">
                      <CardHeader
                        title={this.props.t("Vana +")}
                        titleNote={`(${offerAcceptedandDocumentSentL})`}
                        subtitle={this.props.t("Waiting for approval")}
                        graphic={<CircleIcon light={true} icon="how_to_reg" />}
                      />
                      <CardContent>
                        <RequestList
                          adapter={RequestListAdapter}
                          loading={this.state.loading}
                          requests={this.state.offerAccepted}
                          onReload={this.onReload.bind(this)}
                          onFilter={this.onFilter.bind(this)}
                          onFilterCollection={"documentSentLength"}
                          type="offer_accepted_and_document_sent"
                        />
                      </CardContent>
                    </Card>
                  </Cell>
                </Row>
              </Cell>
            </Row>
          </Grid>
        </Route>
      </Switch>
    );
  }
}

export default compose(Request)(
  withApp,
  withDataLayer,
  withRouter,
  withTranslation()
);
